import { message } from 'antd'
import { useState, useEffect } from 'react'
import { getAdminSettings, updateAdminSettings } from 'Services/admin'

export default function useAdmin() {
  const [settings, setSettings] = useState<any>(null)
  const handleGetSettings = async () => {
    const { data } = await getAdminSettings()
    setSettings(data)
  }

  const handleUpdateSettings = async (updateData: any) => {
    try {
      const data = await updateAdminSettings(updateData)
      setSettings(data)
      message.success('Settings updated successfully', 1)
    } catch (error) {
      message.error('Failed to update settings', 1)
      console.log('error', error)
    }
  }

  useEffect(() => {
    handleGetSettings()
  }, [])

  return { settings, handleUpdateSettings }
}
